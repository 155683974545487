import { Outlet, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import allActions from 'redux/actions';
import axios from '../../axios';

export default function NavBar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { walletAddress } = useSelector(state => state.auth);
    useEffect(() => {
        const getCacheProvider = async () => {
            try {
                await dispatch(allActions.authActions.getCacheProvider());
                await dispatch({ type: 'HOME_PAGE_LOADING', payload: false });
            } catch (error) {
                console.error(error);
            }
        }
        getCacheProvider();
    }, [dispatch, navigate]);

    useEffect(() => {
        const getData = async () => {
            if (walletAddress !== null) {
                try {
                    let userData = await axios.get(`rushsale/getInterestedEthAmount?address=${walletAddress}`);
                    await dispatch({ type: 'LOAD_USER_DETAILS', payload: userData?.data ? userData?.data : {} });
                    if (userData?.data?.screenName) {
                        if (userData?.data?.interestedAmount > 0) {
                            navigate('/steps/result');
                        } else {
                            navigate('/steps/third');
                        }
                    } else {
                        navigate('/steps/second');
                    }
                } catch (error) {
                    console.error(error);
                }
            };
        }
        getData();
    }, [walletAddress, dispatch, navigate]);
    return (
        <>
            <nav>
                <div className="container">
                    <div className={styles.navBar}>
                        <img src="/assets/logo.svg" alt="logo" onClick={() => navigate('/')} />
                        <a href="https://app.bullieverse.com" target="_blank" rel="noopener noreferrer">Play Now</a>
                    </div>
                </div>
            </nav>
            <div className="mainSection container">
                <Outlet />
            </div>
        </>
    )
}
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import allActions from 'redux/actions';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../axios';

export default function First() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isWalletConnecting, walletAddress } = useSelector(state => state.auth);

    const connectWallet = async () => {
        try {
            await dispatch(allActions.authActions.connectWallet());
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const getData = async () => {
            try {
                let userData = await axios.get(`rushsale/getInterestedEthAmount?address=${walletAddress}`);
                await dispatch({ type: 'LOAD_USER_DETAILS', payload: userData?.data ? userData?.data : {} });
                if (userData?.data?.screenName) {
                    if (userData?.data?.interestedAmount > 0) {
                        navigate('/steps/result');
                    } else {
                        navigate('/steps/third');
                    }
                } else {
                    navigate('/steps/second');
                }
            } catch (error) {
                console.error(error);
            }
        };
        if (walletAddress !== null) {
            getData();
        }
    }, [walletAddress, navigate, dispatch]);

    return (
        <div className={styles.box}>
            <div className={styles.innerBox}>
                <div className={styles.stepCount}>
                    Step <span>1</span> of <span>3</span>
                </div>
                <img src='/assets/steps/first/baner.svg' alt='banner' className={styles.baner} />
                <h1>Connect your wallet</h1>
                <p>
                    This is just to create an account
                </p>

                <button onClick={() => connectWallet()} disabled={isWalletConnecting}>
                    <img src='/assets/steps/first/wallet.svg' alt='banner' /> Connect Wallet
                </button>
                <div className={styles.notification}>
                    <img src='/assets/steps/first/person.svg' alt='banner' />This is just to create an account, this process does not involve initiating any transfers.
                </div>
            </div>
        </div>
    )
}
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';

export default function Home() {
    const navigate = useNavigate();
    const { walletAddress, homePageLoading } = useSelector(state => state.auth);

    return (
        <>
            {homePageLoading
                ? (
                    <div className='spinnerWraper'>
                        <div className="spinner"></div>
                    </div>
                )
                : (
                    <div className={styles.box}>
                        <div className={styles.header}>
                            <div className={styles.left}>
                                <h1>Welcome to <span>Rush</span> Pre-sale</h1>
                                <p>
                                    Token Pre-sale Registration
                                </p>
                            </div>
                            <div className={styles.right}>
                                RUSH <img src='/assets/rushflash.svg' alt='logo' />
                            </div>
                        </div>
                        <div className={styles.main}>
                            <div className={styles.step}>
                                <div className={styles.count}>1</div>
                                <img src="/assets/zero/1.svg" alt='pen' />
                                <div className={styles.info}>
                                    Register your Interest
                                </div>
                            </div>

                            <div className={styles.step}>
                                <div className={styles.count}>2</div>
                                <img src="/assets/zero/2.svg" alt='pen' />
                                <div className={styles.info}>
                                    Join the community and Earn your whitelist Spot
                                </div>
                            </div>

                            <div className={styles.step}>
                                <div className={styles.count}>3</div>
                                <img src="/assets/zero/3.svg" alt='pen' />
                                <div className={styles.info}>
                                    Participate in the Pre-Sale when live
                                </div>
                            </div>
                        </div>
                        <button className={styles.button} onClick={() => walletAddress === null ? navigate('/steps/first') : navigate('/steps/second')}>Register Here</button>
                    </div>
                )
            }
        </>
    )
}
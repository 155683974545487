import React from 'react';
import { Outlet, BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Home from 'pages/Home';
import FirstStep from 'pages/Steps/First';
import SecondStep from 'pages/Steps/Second';
import ThirdStep from 'pages/Steps/Third';
import ResultStep from 'pages/Steps/Result';
import NavBar from 'components/NavBar';


class AppRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

        if (navigator.appVersion.indexOf('Mac') > -1) {
            document.body.className += ' MacBody';
        } else if (navigator.appVersion.indexOf('Windows') > -1) {
            document.body.className += ' WindowsBody';
        }

    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='*' element={<Navigate to='/' />} />
                    <Route path='/' element={<NavBar />}>
                        <Route index element={<Navigate to='/home' />} />
                        <Route path='home' element={<Home />} />
                        <Route path='steps' element={<Outlet />}>
                            <Route index element={<Navigate to="/steps/first" />} />
                            <Route path='first' element={<FirstStep />} />
                            <Route path='second' element={<SecondStep />} />
                            <Route path='third' element={<ThirdStep />} />
                            <Route path='result' element={<ResultStep />} />
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authState: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
import styles from './index.module.scss';
import axios from '../../../axios';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Second() {
    const { walletAddress } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (walletAddress === null) {
            navigate('/steps/first');
        }
    }, [walletAddress, navigate]);

    const connectTwitter = async () => {
        setIsLoading(true);
        try {
            let { requestUrl } = await axios.get(`rushsale/twitter/getRequestToken?address=${walletAddress}`);
            window.location.replace(requestUrl);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.box}>
            <div className={styles.innerBox}>
                <div className={styles.stepCount}>
                    Step <span>2</span> of <span>3</span>
                </div>
                <img src='/assets/steps/second/banner.svg' alt='banner' className={styles.baner} />
                <h1>
                    Great! Now connect your Twitter
                </h1>
                <p>
                    Connect your twitter to complete your account creation.
                </p>

                <button onClick={() => connectTwitter()} disabled={isLoading}>
                    <img src='/assets/steps/second/twitter.svg' alt='banner' /> Connect Twitter
                </button>
                <div className={styles.notification}>
                    <img src='/assets/steps/second/info.svg' alt='banner' />
                    Tweeting about $RUSH will increase your chances to get whitelisted
                </div>
            </div>
        </div>
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore from './redux';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import Routes from 'routers';
export const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <React.Suspense fallback={<></>}>
      <Routes />
    </React.Suspense >
  </Provider >
);
reportWebVitals();

export const BUY_CONTRACT_ABI = [
    {
        'inputs': [

        ],
        'stateMutability': 'nonpayable',
        'type': 'constructor'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': 'newDeadLine',
                'type': 'uint256'
            }
        ],
        'name': 'ChangedDeadLine',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': 'newStartTime',
                'type': 'uint256'
            }
        ],
        'name': 'ChangedStartTime',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'contributor',
                'type': 'address'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': 'amount',
                'type': 'uint256'
            }
        ],
        'name': 'Contribution',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'previousOwner',
                'type': 'address'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'newOwner',
                'type': 'address'
            }
        ],
        'name': 'OwnershipTransferred',
        'type': 'event'
    },
    {
        'inputs': [

        ],
        'name': 'capType',
        'outputs': [
            {
                'internalType': 'enum Crowdfunding.CapType',
                'name': '',
                'type': 'uint8'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': '_collectorAddress',
                'type': 'address'
            }
        ],
        'name': 'changeCollectorAddress',
        'outputs': [

        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'newDeadLine',
                'type': 'uint256'
            }
        ],
        'name': 'changeDeadLine',
        'outputs': [

        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'newAddress',
                'type': 'address'
            }
        ],
        'name': 'changeMasterAddress',
        'outputs': [

        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': '_privateSaleMaxContribute',
                'type': 'uint256'
            }
        ],
        'name': 'changePrivateSaleMaxContribute',
        'outputs': [

        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'newStartTime',
                'type': 'uint256'
            }
        ],
        'name': 'changeStartTime',
        'outputs': [

        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [

        ],
        'name': 'collectedAmount',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [

        ],
        'name': 'collectorAddress',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'minAmount',
                'type': 'uint256'
            },
            {
                'internalType': 'uint256',
                'name': 'maxAmount',
                'type': 'uint256'
            },
            {
                'internalType': 'bytes',
                'name': 'signature',
                'type': 'bytes'
            }
        ],
        'name': 'contribute',
        'outputs': [

        ],
        'stateMutability': 'payable',
        'type': 'function'
    },
    {
        'inputs': [

        ],
        'name': 'deadline',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'minAmount',
                'type': 'uint256'
            },
            {
                'internalType': 'uint256',
                'name': 'maxAmount',
                'type': 'uint256'
            },
            {
                'internalType': 'address',
                'name': 'sender',
                'type': 'address'
            },
            {
                'internalType': 'bytes',
                'name': 'signature',
                'type': 'bytes'
            }
        ],
        'name': 'getSignedAddress',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [

        ],
        'name': 'masterAddress',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [

        ],
        'name': 'owner',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'name': 'privateSaleContribute',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [

        ],
        'name': 'privateSaleMaxContribute',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [

        ],
        'name': 'renounceOwnership',
        'outputs': [

        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'enum Crowdfunding.CapType',
                'name': '_capType',
                'type': 'uint8'
            }
        ],
        'name': 'setCapType',
        'outputs': [

        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [

        ],
        'name': 'startTime',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'newOwner',
                'type': 'address'
            }
        ],
        'name': 'transferOwnership',
        'outputs': [

        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    }
] 
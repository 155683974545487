import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export default function ResultStep() {
    const navigate = useNavigate();
    const { userDetails, walletAddress } = useSelector(state => state.auth);

    useEffect(() => {
        if (walletAddress === null) {
            navigate('/steps/first');
        };
    }, [walletAddress, navigate]);

    return (
        <div className={styles.box}>
            <div className={styles.innerBox}>
                <img src='/assets/steps/result/banner.svg' className={styles.banner} alt='banner' />
                <h1>
                    Congrats <span>{userDetails?.screenName} !</span>
                </h1>
                <h1>
                    You have successfully registered your interest.
                </h1>
                {/* <p>
                    Join the community  to receive the latest updates
                </p> */}
                {/* <div className={styles.notification}>
                    <img src='/assets/steps/result/diamond.svg' alt='banner' />
                    Join Diamond Militia to farm airdrop points
                </div> */}
                <div className={styles.socialWraper}>
                    <a
                        href='https://discord.com/invite/bullieverse'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img src='/assets/social/discord.svg' alt='discord' />
                    </a>
                    <a
                        href='https://twitter.com/Bullieverse'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img src='/assets/social/x.svg' alt='x' />
                    </a>
                    <a
                        href='https://t.me/Bullieverse'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img src='/assets/social/telegram.svg' alt='telegram' />
                    </a>
                </div>
            </div>
        </div >
    )
}
import { BUY_CONTRACT_ABI } from './BUY_CONTRACT_ABI';

const configuration = {
    BUY_CONTRACT_ADDRESS_ABI: BUY_CONTRACT_ABI,
    BUY_CONTRACT_ADDRESS: process.env.REACT_APP_BUY_CONTRACT_ADDRESS,
    ETH_PRICE: 1876,
    RUSH_PRICE_USD: 0.0005,
    INFURA_ID: process.env.REACT_APP_INFURA_ID,
    ETH_INFURA_URL: process.env.REACT_APP_ETH_INFURA_URL,
    POLYGON_INFURA_URL: process.env.REACT_APP_POLYGON_INFURA_URL,
    Amplify_Details: {
        cognitoPoolId: process.env.REACT_APP_ENV === 'PROD' ? 'us-east-1_FURDu5JcV' : 'us-west-1_VE1PbDfIh',
        cognitoWebClientId: process.env.REACT_APP_ENV === 'PROD' ? '673bks08hlskcq5eqesjig0j77' : '1kp0kpe5c221hk9vliuqnolmt6',
        region: process.env.REACT_APP_ENV === 'PROD' ? 'us-east-1' : 'us-west-1',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    NETWORKS: {
        'POLYGON': {
            chainId: process.env.REACT_APP_ENV === 'PROD' ? '0x89' : '0x13881',
            chainName: process.env.REACT_APP_ENV === 'PROD' ? 'Matic(Polygon) Mainnet' : 'Mumbai',
            nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18
            },
            rpcUrls: [process.env.REACT_APP_ENV === 'PROD' ? 'https://polygon-rpc.com' : 'https://rpc-mumbai.maticvigil.com'],
            blockExplorerUrls: [process.env.REACT_APP_ENV === 'PROD' ? 'https://www.polygonscan.com/' : 'https://mumbai.polygonscan.com/']
        },
        'ETH': {
            chainId: process.env.REACT_APP_ENV === 'PROD' ? '0x1' : '0x5',
            chainName: 'Ethereum Mainnet',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18
            },
            rpcUrls: [
                'https://mainnet.infura.io/v3/'
            ],
            blockExplorerUrls: [
                'https://etherscan.io'
            ]
        }
    },
    API_PREFIX: process.env.REACT_APP_API_PREFIX
};
export default configuration;

import Config from 'Config';
import Amplify from 'aws-amplify';

const initialState = {
    isAuthenticated: false,
    isWalletConnecting: false,
    walletAddress: null,
    web3Provider: null,
    ETH_INFURA_URL: Config.ETH_INFURA_URL,
    POLYGON_INFURA_URL: Config.POLYGON_INFURA_URL,
    userDetails: {},
    homePageLoading: true
};

export default function authReducer(state = initialState, action) {

    switch (action.type) {
        case 'isWalletConnecting':
            return {
                ...state,
                isWalletConnecting: action.payload,
            };
        case 'LOAD_USER_DETAILS':
            return {
                ...state,
                userDetails: action.payload,
            };
        case 'USER_SIGN_OUT':
            return {
                ...state,
                walletAddress: null
            };
        case 'LOAD_WALLET_ADDRESS':
            return {
                ...state,
                walletAddress: action.payload
            };
        case 'LOAD_WEB3_PROVIDER':
            return {
                ...state,
                web3Provider: action.payload,
            };
        case 'HOME_PAGE_LOADING':
            return {
                ...state,
                homePageLoading: action.payload,
            };
        default:
            return state;
    }
}
setupCognito();
function setupCognito() {
    const cognitoPoolId = Config.Amplify_Details.cognitoPoolId;
    const cognitoWebClientId = Config.Amplify_Details.cognitoWebClientId;
    const region = Config.Amplify_Details.region;

    if (!cognitoPoolId || !cognitoWebClientId) {
        return;
    }

    Amplify.configure({
        Auth: {
            region,
            userPoolId: cognitoPoolId,
            userPoolWebClientId: cognitoWebClientId,
            authenticationFlowType:
                Config.Amplify_Details.authenticationFlowType,
        },
    });
}
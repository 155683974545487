import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import axios from '../../../axios';
import { useSelector } from 'react-redux';

export default function Third() {

    const navigate = useNavigate();
    const [amount, setAmount] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { walletAddress } = useSelector(state => state.auth);

    useEffect(() => {
        if (walletAddress === null) {
            navigate('/steps/first');
        }
    }, [walletAddress, navigate]);

    const confirmButtonClicked = async () => {
        setIsLoading(true);
        try {
            await axios.post(`/rushsale/addInterestedAmount?interestedAmount=${amount}&address=${walletAddress}`);
            navigate('/steps/result');
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.box}>
            <div className={styles.innerBox}>
                <div className={styles.stepCount}>
                    Step <span>3</span> of <span>3</span>
                </div>
                <img src='/assets/steps/third/banner.svg' alt='banner' className={styles.baner} />
                <h1>
                    Almost there!
                </h1>
                <p>
                    Let us know the amount of ETH you are planning to spend on Rush Tokens
                </p>
                <input type='number' className={styles.inputBox} placeholder='Enter your Amount in ETH' value={amount || ''} onChange={(e) => setAmount(e.target.value)} />
                <button onClick={() => confirmButtonClicked()} disabled={amount <= 0 || isLoading}>
                    Confirm
                </button>
                <div className={styles.notification}>
                    <img src='/assets/steps/third/i.svg' alt='banner' />
                    This is just to register your interest and not a determining factor for your whitelist spot
                </div>
            </div>
        </div>
    )
}